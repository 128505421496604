export default [
    {
        name: 'ProductionProjectionList',
        path: 'analysis/projections',
        component: () => import('./views/ProductionProjectionList.vue'),
        meta: {
            title: 'Projections',
            perms: 'finances',
            icon: 'fa-solid fa-arrow-trend-up',
        },
    },
    {
        name: 'ProductionProjectionCreate',
        path: 'analysis/projections/create',
        component: () => import('./views/ProductionProjectionForm.vue'),
    },
    {
        name: 'ProductionProjectionUpdate',
        path: 'analysis/projections/:projection',
        component: () => import('./views/ProductionProjectionForm.vue'),
    },
    {
        name: 'ProductionProjectionMarketCreate',
        path: 'analysis/projections/:projection/markets/create',
        component: () => import('./views/ProductionProjectionMarketForm.vue'),
    },
    {
        name: 'ProductionProjectionMarketUpdate',
        path: 'analysis/projections/:projection/markets/:market',
        component: () => import('./views/ProductionProjectionMarketForm.vue'),
    },
]
