export default {
    props: {
        // State
        fields: {
            type: Array,
            default: () => [],
        },
        itemActions: {
            type: Array,
            default: () => [],
        },
        modelActions: {
            type: Array,
            default: () => [],
        },

        // Behaviour
        showIndex: {
            type: Boolean,
            default: false,
        },
        hideTopbar: {
            type: Boolean,
            default: false,
        },
        checkable: {
            type: Boolean,
            default: false,
        },

        // Appareance
        checkPosition: {
            type: String,
            default: 'left',
            validator: (value) => ['left', 'right'].includes(value),
        },
    },
    computed: {
        columnCount() {
            let result = this.fields.length

            if (this.checkable) {
                result++
            }

            if (this.showIndex) {
                result++
            }

            if (this.itemActions || this.modelActions) {
                result++
            }

            return result
        },
        itemButtonsCount() {
            return this.itemActions.length
        },
    },
}
