export default [
    {
        name: 'POSOrderList',
        path: 'banking/pos/orders',
        component: () => import('./views/POSOrderList.vue'),
        meta: {
            title: 'POS Orders',
            perms: 'finances',
            icon: 'fa-solid fa-credit-card',
        },
    },

    {
        name: 'TransferList',
        path: 'banking/transfers',
        component: () => import('./views/TransferList.vue'),
        meta: {
            title: 'Bank Transfers',
            perms: 'finances',
            icon: 'fa-solid fa-exchange-alt',
        },
    },
    {
        name: 'TransferCreate',
        path: 'banking/transfers/create',
        component: () => import('./views/TransferForm.vue'),
    },
    {
        name: 'TransferUpdate',
        path: 'banking/transfers/:transfer',
        component: () => import('./views/TransferForm.vue'),
    },

    {
        name: 'BankAccountList',
        path: 'banking/accounts',
        component: () => import('./views/BankAccountList.vue'),
        meta: {
            title: 'Bank Accounts',
            perms: 'finances',
            icon: 'fa-solid fa-money-check',
        },
    },
    {
        name: 'BankAccountCreate',
        path: 'banking/accounts/create',
        component: () => import('./views/BankAccountForm.vue'),
    },
    {
        name: 'BankAccountUpdate',
        path: 'banking/accounts/:account',
        component: () => import('./views/BankAccountForm.vue'),
    },

    {
        name: 'OrderDailyLogList',
        path: 'dailylogs',
        component: () => import('./views/OrderDailyLogList.vue'),
        meta: {
            title: 'Order Daily Log',
            perms: 'finances',
            icon: 'fa-solid fa-calendar-days',
        },
    },
    {
        name: 'OrderDailyLogCreate',
        path: 'dailylogs/create',
        component: () => import('./views/OrderDailyLogForm.vue'),
    },
    {
        name: 'OrderDailyLogUpdate',
        path: 'dailylogs/:account',
        component: () => import('./views/OrderDailyLogForm.vue'),
    },
]
