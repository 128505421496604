<template>
    <div
        class="field"
        :class="fieldClasses"
    >
        <label
            v-if="label"
            class="label is-small"
            :class="{
                'has-text-right': rightAligned,
            }"
        >
            {{ label }}
        </label>

        <div :class="controlClasses">
            <slot></slot>
            <span
                v-if="icon"
                class="icon is-left is-small"
            >
                <i :class="icon"></i>
            </span>
        </div>

        <slot name="extra"></slot>

        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Control from './Control'

export default {
    name: 'T3Control',
    extends: Control,
    props: {
        fieldClasses: {
            type: Array,
            default: () => [],
        },
        expanded: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        controlClasses() {
            let classes = ['control']
            if (this.icon) {
                classes.push('has-icons-left')
            }
            if (this.expanded) {
                classes.push('is-expanded')
            }
            return classes
        },
    },
}
</script>
