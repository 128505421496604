export default [
    {
        name: 'AccountingAccountList',
        path: 'accounting/accounts',
        component: () => import('./views/AccountingAccountList.vue'),
        meta: {
            title: 'Accounts',
            perms: 'advisor',
        },
    },
    {
        name: 'AccountingAccountCreate',
        path: 'accounting/accounts/create',
        component: () => import('./views/AccountingAccountForm.vue'),
    },
    {
        name: 'AccountingAccountUpdate',
        path: 'accounting/accounts/:account',
        component: () => import('./views/AccountingAccountForm.vue'),
    },
    {
        name: 'AccountingPeriodList',
        path: 'accounting/periods',
        component: () => import('./views/AccountingPeriodList.vue'),
        meta: {
            title: 'Periods',
            perms: 'advisor',
        },
    },
    {
        name: 'AccountingPeriodCreate',
        path: 'accounting/periods/create',
        component: () => import('./views/AccountingPeriodCreate.vue'),
    },
    {
        name: 'AccountingPeriodUpdate',
        path: 'accounting/periods/:period',
        component: () => import('./views/AccountingPeriodUpdate.vue'),
    },
    {
        name: 'TCAccountingPeriodPreview',
        path: 'accounting/periods/tc/preview',
        component: () => import('./views/TCAccountingPeriodPreview.vue'),
        meta: {
            title: 'TC Period Preview',
            perms: 'advisor',
        },
    },
]
