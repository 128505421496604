export default [
    {
        name: 'EUHotelBuyInvoiceList',
        path: 'finances/invoices/eu_buy',
        component: () => import('./views/EUHotelBuyInvoiceList.vue'),
        meta: {
            title: 'EU Hotel Buy Invoices',
            perms: 'finances',
            icon: 'fa-solid fa-file-invoice',
        },
    },
    {
        name: 'IRPFInvoiceList',
        path: 'finances/invoices/irpf',
        component: () => import('./views/IRPFInvoiceList.vue'),
        meta: {
            title: 'IRPF Invoices',
            perms: 'finances',
            icon: 'fa-solid fa-file-invoice',
        },
    },
    {
        name: 'IRPFInvoiceCreate',
        path: 'finances/invoices/irpf/create',
        component: () => import('./views/IRPFInvoiceForm.vue'),
    },
    {
        name: 'IRPFInvoiceUpdate',
        path: 'finances/invoices/irpf/:invoice',
        component: () => import('./views/IRPFInvoiceForm.vue'),
    },
]
