import { defineStore } from 'pinia'
import _axios from '../plugins/axios'

export const useChoicesStore = defineStore('choices', {
    state: () => ({
        choices: {},
    }),
    getters: {
        choicesList(state) {
            return (name) => state.choices[name]
        },
        choicesRow(state) {
            return (name, value) => {
                const choices = state.choices[name]
                if (choices === undefined) {
                    return ''
                }
                return choices.find((choice) => choice[0] === value)
            }
        },
        choicesLabel(state) {
            return (name, value) => {
                const choices = state.choices[name]
                if (choices === undefined) {
                    return ''
                }
                return choices.find((choice) => choice[0] === value)?.[1]
            }
        },
    },
    actions: {
        async fetchChoices() {
            try {
                const response = await _axios.post('/choices')
                this.choices = response.data.body
            } catch (error) {
                console.log(error)
            }
        },
    },
})
