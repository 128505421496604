export default [
    {
        name: 'ExpenseList',
        path: 'finances/expenses',
        component: () => import('./views/ExpenseList.vue'),
        meta: {
            title: 'Expenses',
            perms: 'finances',
            icon: 'fa-solid fa-money-bill-alt',
        },
    },
    {
        name: 'ExpenseCreate',
        path: 'finances/expenses/create',
        component: () => import('./views/ExpenseForm.vue'),
    },
    {
        name: 'ExpenseUpdate',
        path: 'finances/expenses/:expense',
        component: () => import('./views/ExpenseForm.vue'),
    },

    {
        name: 'IncomingList',
        path: 'finances/incomings',
        component: () => import('./views/IncomingList.vue'),
        meta: {
            title: 'Incomings',
            perms: 'finances',
            icon: 'fa-solid fa-money-bill-alt',
        },
    },
    {
        name: 'IncomingCreate',
        path: 'finances/incomings/create',
        component: () => import('./views/IncomingForm.vue'),
    },
    {
        name: 'IncomingUpdate',
        path: 'finances/incomings/:incoming',
        component: () => import('./views/IncomingForm.vue'),
    },
]
