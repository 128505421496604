import AccountingRoutes from './modules/accounting/routes'
import OperationsRoutes from './modules/operations/routes'
import InvoiceRoutes from './modules/invoices/routes'
import ModelsRoutes from './modules/models/routes'

export default [
    {
        name: 'LeadFinances',
        path: 'finances/leads',
        component: () => import('./views/LeadFinances.vue'),
        meta: {
            title: 'Lead Finances',
            perms: 'finances',
            icon: 'fa-solid fa-chart-bar',
        },
    },
    {
        name: 'SupplierFinances',
        path: 'finances/suppliers',
        component: () => import('./views/SupplierFinances.vue'),
        meta: {
            title: 'Supplier Finances',
            perms: 'finances',
            icon: 'fa-solid fa-parachute-box',
        },
    },
].concat(AccountingRoutes, OperationsRoutes, InvoiceRoutes, ModelsRoutes)
