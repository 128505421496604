export default [
    {
        name: 'EmailAccountList',
        path: 'crm/accounts',
        component: () => import('./views/EmailAccountList.vue'),
        meta: {
            title: 'Email Accounts',
            perms: 'crm',
            icon: 'fa-solid fa-at',
        },
    },
    {
        name: 'EmailAccountCreate',
        path: 'crm/accounts/create',
        component: () => import('./views/EmailAccountForm.vue'),
    },
    {
        name: 'EmailAccountUpdate',
        path: 'crm/accounts/:account',
        component: () => import('./views/EmailAccountForm.vue'),
    },
    {
        name: 'CRMInbox',
        path: 'crm/inbox',
        component: () => import('./views/CRMInbox.vue'),
        meta: {
            title: 'CRM Inbox',
            perms: 'crm',
            icon: 'fa-solid fa-inbox',
        },
    },
    {
        name: 'SystemEmailTemplates',
        path: 'crm/templates/system',
        component: () => import('./views/SystemEmailTemplates.vue'),
        meta: {
            title: 'System Email Templates',
            perms: 'crm',
            icon: 'fa-solid fa-envelope-open-text',
        },
    },
    {
        name: 'FrequentAnswerList',
        path: 'crm/frequentanswers',
        component: () => import('./views/FrequentAnswerList.vue'),
        meta: {
            title: 'Frequent Answers',
            perms: 'crm',
            icon: 'fa-solid fa-comment',
        },
    },
    {
        name: 'FrequentAnswerCreate',
        path: 'crm/frequentanswers/create',
        component: () => import('./views/FrequentAnswerForm.vue'),
    },
    {
        name: 'FrequentAnswerUpdate',
        path: 'crm/frequentanswers/:answer',
        component: () => import('./views/FrequentAnswerForm.vue'),
    },
    {
        name: 'CRMMessageList',
        path: 'crm/messages/list',
        component: () => import('./components/CRMMessageList.vue'),
        meta: {
            title: 'Email List',
            perms: 'crm',
            icon: 'fa-solid fa-table',
        },
    },
]
