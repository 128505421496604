export default [
    {
        name: 'PaymentList',
        path: 'payments',
        component: () => import('./views/PaymentList.vue'),
        meta: {
            title: 'Payments',
            perms: 'leads',
        },
    },
]
