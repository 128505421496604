export default [
    {
        name: 'Model347',
        path: 'finances/models/347',
        component: () => import('./views/Model347.vue'),
        meta: {
            title: 'Model 347',
            perms: 'advisor',
        },
    },
]
