export default [
    {
        name: 'TagList',
        path: 'tags',
        component: () => import('./views/TagList.vue'),
        meta: {
            title: 'Tags',
            perms: 'admin',
            icon: 'fa-solid fa-tags',
        },
    },
]
