export default [
    {
        name: 'LoginLogList',
        path: 'logs/logins',
        component: () => import('./views/LoginLogList.vue'),
        meta: {
            title: 'Login Log',
            perms: 'admin',
            icon: 'fa-solid fa-clipboard-list',
        },
    },
]
