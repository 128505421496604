export default [
    {
        name: 'PendingIncomingPayments',
        path: 'tasks/payments/pending/incoming',
        component: () => import('./views/PendingIncomingPaymentsList.vue'),
        meta: {
            title: 'Pending Incoming Payments',
            perms: 'tasks',
            icon: 'fa-solid fa-tasks',
        },
    },
    {
        name: 'PendingIncomingPaymentsAtCredit',
        path: 'tasks/payments/pending/incoming/credit',
        component: () => import('./views/PendingIncomingPaymentsAtCredit.vue'),
        meta: {
            title: 'Pending Incoming Payments at Credit',
            perms: 'tasks',
            icon: 'fa-solid fa-tasks',
        },
    },
    {
        name: 'PendingOutgoingPayments',
        path: 'tasks/payments/pending/outgoing',
        component: () => import('./views/PendingOutgoingPayments.vue'),
        meta: {
            title: 'Pending Outgoing Payments',
            perms: 'tasks',
            icon: 'fa-solid fa-tasks',
        },
    },
    {
        name: 'ClaimedTodayPayments',
        path: 'tasks/payments/pending/claimed/today',
        component: () => import('./views/ClaimedTodayPayments.vue'),
        meta: {
            title: 'Claimed Today Payments',
            perms: 'tasks',
            icon: 'fa-solid fa-tasks',
        },
    },
    {
        name: 'UnprocessedCardPayments',
        path: 'tasks/card/payments/tpv/unprocessed',
        component: () => import('./views/UnprocessedCardPayments.vue'),
        meta: {
            title: 'Unprocessed Card Payments',
            perms: 'tasks',
            icon: 'fa-solid fa-tasks',
        },
    },
    {
        name: 'LeadsWithPendingQuotes',
        path: 'tasks/leads/quotes/pending',
        component: () => import('./views/LeadsWithPendingQuotes.vue'),
        meta: {
            title: 'Leads With Pending Quotes',
            perms: 'tasks',
            icon: 'fa-solid fa-tasks',
        },
    },
    {
        name: 'QuotesSavedButNotSent',
        path: 'tasks/leads/quotes/unsent',
        component: () => import('./views/QuotesSavedButNotSent.vue'),
        meta: {
            title: 'Leads with Unsent Quotes',
            perms: 'tasks',
            icon: 'fa-solid fa-tasks',
        },
    },
    {
        name: 'QuotesSentAtDate',
        path: 'tasks/leads/quotes/sent',
        component: () => import('./views/QuotesSentAtDate.vue'),
        meta: {
            title: 'Quotes Sent at Date',
            perms: 'tasks',
            icon: 'fa-solid fa-tasks',
        },
    },
    {
        name: 'LeadsWithReminder',
        path: 'tasks/leads/reminded',
        component: () => import('./views/LeadsWithReminder.vue'),
        meta: {
            title: 'Leads With Reminders',
            perms: 'tasks',
            icon: 'fa-solid fa-tasks',
        },
    },
    {
        name: 'UnreceivedRoomingList',
        path: 'tasks/leads/rooming/unreceived',
        component: () => import('./views/UnreceivedRoomingList.vue'),
        meta: {
            title: 'Requested Rooming List',
            perms: 'tasks',
            icon: 'fa-solid fa-tasks',
        },
    },
    {
        name: 'LeadsTicketingTasks',
        path: 'tasks/leads/ticketing',
        component: () => import('./views/LeadsTicketingTasks.vue'),
        meta: {
            title: 'Lead Ticketing Tasks',
            perms: 'tasks',
            icon: 'fa-solid fa-tasks',
        },
    },
    {
        name: 'HotelModificationRequestList',
        path: 'tasks/hotels/modifications',
        component: () => import('./views/HotelModificationRequestList.vue'),
        meta: {
            title: 'Hotel Info Modification Request',
            perms: 'hotels',
            icon: 'fa-solid fa-info-circle',
        },
    },
    {
        name: 'HotelModificationRequestUpdate',
        path: 'tasks/hotels/modifications/:modification',
        component: () => import('./views/HotelModificationRequestUpdate.vue'),
    },
    {
        name: 'HoteliersPendingFromVerification',
        path: 'tasks/hoteliers/pending/verification',
        component: () => import('./views/HoteliersPendingFromVerification.vue'),
        meta: {
            title: 'Hoteliers Pending from Verification',
            perms: 'tasks',
            icon: 'fa-solid fa-user-check',
        },
    },
    {
        name: 'PendingLeadHotelMap',
        path: 'tasks/mappings/pending',
        component: () => import('./views/PendingLeadHotelMap.vue'),
        meta: {
            title: 'Pending Mappings',
            perms: 'tasks',
            icon: 'fa-solid fa-signs-post',
        },
    },
    {
        name: 'CallPendingLeadHotelMap',
        path: 'tasks/mappings/call_pending',
        component: () => import('./views/PendingLeadHotelMap.vue'),
        meta: {
            title: 'Call Pending Mappings',
            perms: 'tasks',
            icon: 'fa-solid fa-phone',
        },
    },
    {
        name: 'SupplierInvoicesMissing',
        path: 'tasks/invoices/suppliers',
        component: () => import('./views/SupplierInvoicesMissing.vue'),
        meta: {
            title: 'Supplier Invoices Missing',
            perms: 'tasks',
            icon: 'fa-solid fa-file-invoice',
        },
    },
    {
        name: 'LeadHotelCallList',
        path: 'tasks/leads/hotels/calls',
        component: () => import('./views/LeadHotelCallList.vue'),
        meta: {
            title: 'Lead Hotel Call List',
            perms: 'tasks',
            icon: 'fa-solid fa-headset',
        },
    },
]
