export default [
    {
        name: 'payment_completed',
        path: 'payments/:order/completed',
        component: () => import('@/views/PaymentCompleted.vue'),
    },
    {
        name: 'payment_cancelled',
        path: 'payments/:order/cancelled',
        component: () => import('@/views/PaymentCancelled.vue'),
    },

    {
        name: 'PaymentMethodList',
        path: 'payments/methods',
        component: () => import('./views/PaymentMethodList.vue'),
        meta: {
            title: 'Payment Methods',
            perms: 'payments',
            icon: 'fa-solid fa-wallet',
        },
    },
    {
        name: 'PaymentMethodCreate',
        path: 'payments/methods/create',
        component: () => import('./views/PaymentMethodForm.vue'),
    },
    {
        name: 'PaymentMethodUpdate',
        path: 'payments/methods/:method',
        component: () => import('./views/PaymentMethodForm.vue'),
    },
]
