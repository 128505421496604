import isNil from 'lodash.isnil'

export const percent = function(value) {
    if (isNil(value) || Number.isNaN(value)) {
        return '0.00 %'
    }
    return `${value.toFixed(2)} %`
}

export const tagify = function(value) {
    if (isNil(value)) {
        return ''
    }
    return value
        .split(':')
        .map((item) => {
            let color = ''
            switch (item) {
                case 'Open': {
                    color = 'open-status'
                    break
                }
                case 'Quoted': {
                    color = 'quoted-status'
                    break
                }
                case 'Pre Confirmed': {
                    color = 'pre-confirmed-status'
                    break
                }
                case 'On Option': {
                    color = 'on-option-status'
                    break
                }
                case 'On Modifications': {
                    color = 'modifications-status'
                    break
                }
                case 'Confirmed': {
                    color = 'confirmed-status'
                    break
                }
                case 'Paid': {
                    color = 'paid-status'
                    break
                }
                case 'I': {
                    color = 'in-status'
                    break
                }
                case 'O': {
                    color = 'out-status'
                    break
                }
                case 'Cancelled': {
                    color = 'cancelled-status'
                    break
                }
                case 'Closed': {
                    color = 'closed-status'
                    break
                }
                case 'New': {
                    color = 'new-status'
                    break
                }
                case 'Recent': {
                    color = 'recent-status'
                    break
                }
                case 'Pending': {
                    color = 'pending-status'
                    break
                }
                case 'Quote Sent': {
                    color = 'quote-sent-status'
                    break
                }
                case 'Yes': {
                    color = 'yes_tag'
                    break
                }
                case 'No': {
                    color = 'no_tag'
                    break
                }
                case 'On Request': {
                    color = 'on-request-status'
                    break
                }
                case 'Accepted': {
                    color = 'accepted-status'
                    break
                }
                case 'Rejected': {
                    color = 'rejected-status'
                    break
                }
                case 'Discarded': {
                    color = 'closed-status'
                    break
                }
            }
            return `<span class="tag is-small ${color}">${item}</span>`
        })
        .join(' ')
}

export default {
    percent: percent,
    float: function(value, places) {
        return value.toFixed(places)
    },
    date: function(value) {
        return new Date(value * 1000).toTimeString()
    },
    lines: function(value) {
        return value.join('<br>')
    },
    tagify: tagify,
}
