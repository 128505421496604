import Vue from 'vue'
import VueRouter from 'vue-router'

import AdminRoutes from '@/modules/admin/routes'
import AnalysisRoutes from '@/modules/analysis/routes'
import AppsRoutes from '@/modules/apps/routes'
import BankingRoutes from '@/modules/banking/routes'
import CRMRoutes from '@/modules/crm/routes'
import ClientRoutes from '@/modules/clients/routes'
import DashboardRoutes from '@/modules/dashboard/routes'
import FinancesRoutes from '@/modules/finances/routes'
import HotelsRoutes from '@/modules/hotels/routes'
import HumanResourcesRoutes from '@/modules/hr/routes'
import LeadRoutes from '@/modules/leads/routes'
import LocationRoutes from '@/modules/locations/routes'
import LogsRoutes from '@/modules/logs/routes'
import MailingsRoutes from '@/modules/mailings/routes'
import MarketingRoutes from '@/modules/marketing/routes'
import PaymentsRoutes from '@/modules/payments/routes'
import ReportRoutes from '@/modules/reports/routes'
import SalesChannelsRoutes from '@/modules/saleschannels/routes'
import SuppliersRoutes from '@/modules/suppliers/routes'
import TagRoutes from '@/modules/tags/routes'
import TasksRoutes from '@/modules/tasks/routes'
import TicketingRoutes from '@/modules/ticketing/routes'
import UsersRoutes from '@/modules/users/routes'

import { useAuthStore } from '@/stores/auth'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    routes: [
        {
            name: 'quote_group_submit',
            path: '/hotels/quotes/group/:reference/details',
            redirect: {
                path: 'login',
            },
        },
        {
            name: 'restartClient',
            path: '/restart',
            component: () => import('@/views/RestartPage.vue'),
            meta: { noAuth: true },
        },
        {
            path: '/',
            component: () => import('@/views/structure/BaseLayout.vue'),
            redirect: {
                path: 'dashboard',
            },
            children: [
                {
                    name: 'login',
                    path: '/login',
                    component: () => import('@/views/LoginPage.vue'),
                    meta: { noAuth: true, skipAuth: true },
                },
                {
                    name: 'terms_and_conditions',
                    path: '/terms',
                    component: () => import('@/views/TermsAndConditions.vue'),
                },
                {
                    name: 'not_found',
                    path: '404',
                    component: () => import('@/views/NotFound.vue'),
                },
            ].concat(
                AdminRoutes,
                AnalysisRoutes,
                AppsRoutes,
                BankingRoutes,
                CRMRoutes,
                ClientRoutes,
                DashboardRoutes,
                FinancesRoutes,
                HotelsRoutes,
                HumanResourcesRoutes,
                LeadRoutes,
                LocationRoutes,
                LogsRoutes,
                MailingsRoutes,
                MarketingRoutes,
                PaymentsRoutes,
                ReportRoutes,
                SalesChannelsRoutes,
                SuppliersRoutes,
                TagRoutes,
                TasksRoutes,
                TicketingRoutes,
                UsersRoutes
            ),
        },
        {
            path: '*',
            redirect: { path: '/404' },
        },
    ],
})

export const matchRoute = function (name) {
    const matches = router.matcher.match({ name }).matched
    if (matches.length > 0) {
        for (const route of matches) {
            if (route.name === name) {
                return route
            }
        }
    }
    return undefined
}

var paramsCache = {}

export const routeParams = function (name) {
    if (paramsCache[name] === undefined) {
        const route = matchRoute(name)
        if (route !== undefined) {
            paramsCache[name] = route.path
                .split('/')
                .filter((x) => x.charAt(0) === ':')
                .map((x) => x.substr(1))
        } else {
            paramsCache[name] = null
        }
    }
    return paramsCache[name] || undefined
}

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore()
    const loggedIn = authStore.token !== null

    if (loggedIn) {
        if (to.matched.some((record) => record.meta.skipAuth)) {
            next({
                path: '/dashboard',
                query: { redirect: to.fullPath },
            })
        } else {
            next()
        }
    } else {
        if (to.matched.some((record) => record.meta.noAuth)) {
            next()
        } else {
            next({
                path: '/login',
                query: { redirect: to.fullPath },
            })
        }
    }
})

export default router
